import { Button } from "~/components/ui/button";
import { cn } from "~/lib/utils";

const GlowingButton = ({ children, className = "" }) => {
  return (
    <div className={cn("group relative", className)}>
      <div className="absolute left-0 top-0 h-full w-full blur group-hover:bg-gradient-to-r group-hover:from-[#ebe412b3] group-hover:to-[#68ce8db3] group-active:from-[#ABD84F] group-active:to-[#09C0E9]"></div>
      <Button  aria-label='button' className="relative h-[45px] md:h-[57px] w-full border-none bg-gradient-to-r from-[#ABD84F] to-[#09C0E9] px-5 text-black hover:from-[#EBE212] hover:to-[#68CE8E] active:from-[#ABD84F] active:to-[#09C0E9]">
        {children}
      </Button>
    </div>
  );
};

export default GlowingButton;
