"use client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui/form";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { useToast } from "~/components/ui/use-toast";
import { ArrowLongRight } from "~/components/icons";
import GlowingButton from "~/components/reusables/glowing-button";

const formSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
  feedback: z.string().min(1, { message: "Feedback is required" }),
  acceptTerms: z
    .string()
    .refine((val) => val === "true", { message: "You must accept the terms." }),
});

const GoogleEmbedForm = () => {
  const { toast } = useToast();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      feedback: "",
      acceptTerms: "true",
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      await axios.post("/api/google-customer-feedback-form", {
        email: values.email,
        feedback: values.feedback,
        acceptTerms: "Yes",
      });
      form.reset();
      setIsSubmitted(true);
    } catch (error) {
      toast({
        title: "Something went wrong",
        description: "Please try again",
      });
    }
  };
  if (isSubmitted) {
    return (
      <div className="flex h-full w-full flex-col justify-center gap-8 md:gap-12">
        <div>
          <h2 className="font-h2">Thank you for your feedback!</h2>
          <p className="font-h5 my-[30px]">
            Curious about emerging tech and groundbreaking innovations shaping
            the future? Subscribe to The Shift newsletter!
          </p>
        </div>
        <div className="max-w-[550px]">
          <GlowingButton>
            <a href="#theshift" className="font-btn-lg">
              Subscribe
            </a>
          </GlowingButton>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <h2 className="font-h2">
        What kind of content do you want to see on our blog?
      </h2>
      <h5 className="font-h5 my-[30px]">
        We want to hear from you! Help us deliver the insights and knowledge
        you're seeking by letting us know which topic most piques your interest.
      </h5>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-h6">Email*</FormLabel>
                <FormControl>
                  <Input
                    className="md:font-btn h-11 w-full rounded-md border-black px-3 text-black"
                    placeholder="Enter email..."
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="feedback"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    className="md:font-btn h-11 w-full rounded-md border-black px-3 text-black"
                    placeholder="Type your suggestion here..."
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="acceptTerms"
            defaultValue={"true"}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div className="flex items-center space-x-2">
                    <label
                      htmlFor="acceptTerms"
                      className="flex items-start space-x-2 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      <input
                        type="checkbox"
                        {...field}
                        checked={field.value === "true"}
                        onChange={(e) => {
                          e.target.checked  && form.setValue(
                            "acceptTerms",
                             "true",
                          );
                        }}
                        className="mt-1"
                      />
                      <p className="font-sm font-light leading-relaxed">
                        By submitting this form, you agree that Cisco may
                        process your personal information as described in its
                        Online Privacy Statement. Cisco may contact you with
                        future updates and information related to the Outshift
                        blog. You can unsubscribe at any time.
                      </p>
                    </label>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="inline-block">
            <div className="group relative">
              <div className="absolute left-0 top-0 h-full w-full blur group-hover:bg-gradient-to-r group-hover:from-[#ebe412b3] group-hover:to-[#68ce8db3] group-active:from-[#ABD84F] group-active:to-[#09C0E9]"></div>
              <Button
                type="submit"
                aria-label='Submit'
                className="relative h-[45px] w-full border-none bg-gradient-to-r from-[#ABD84F] to-[#09C0E9] px-5 text-black hover:from-[#EBE212] hover:to-[#68CE8E] active:from-[#ABD84F] active:to-[#09C0E9] md:h-[57px]"
              >
                <p className="mr-3 text-base">Submit</p>
                <ArrowLongRight size={32} color={"black"} />
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default GoogleEmbedForm;
