import { Button } from "~/components/ui/button";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
} from "components/ui/pagination";

const PaginationComponent = ({
  totalPages,
  currentPage,
  paginate,
}: {
  totalPages: number;
  currentPage: number;
  paginate: (pageNumber: any) => void;
}) => {
  return (
    <div className="pt-12">
      <Pagination>
        <PaginationContent>
          {[...Array(totalPages).keys()].map((number) => (
            <>
              {(number == 0 ||
                number == totalPages - 1 ||
                (number < currentPage + 2 && number > currentPage - 4) ||
                (currentPage == 0 && number == 3) ||
                (currentPage == totalPages - 1 &&
                  number == totalPages - 2)) && (
                <Button
                  variant="outline"
                  className={`rounded-md border border-black hover:bg-gradient-blue-purple hover:text-white ${
                    currentPage == number + 1
                      ? "bg-gradient-pagination text-white"
                      : ""
                  }`}
                  key={number + 1}
                  aria-label={(number + 1).toString()}
                  onClick={() => paginate(number + 1)}
                >
                  {number + 1}
                </Button>
              )}
              {(number == currentPage + 3 ||
                number == currentPage - 5 ||
                (currentPage == 0 && number == 4) ||
                (currentPage == totalPages && number == currentPage - 5)) && (
                <PaginationEllipsis key={number + "-ellipsis"} />
              )}
            </>
          ))}
          <Button
            variant={"ghost"}
            aria-label="Next"
            disabled={currentPage == totalPages}
            onClick={() => paginate(currentPage + 1)}
          >
            <span>{`Next >`}</span>
          </Button>
        </PaginationContent>
      </Pagination>
    </div>
  );
};

export default PaginationComponent;
